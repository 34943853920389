import React, { useContext } from 'react'
import { makeStyles } from "@mui/styles";
import { AuthContext } from 'context/Auth.context';
import Barcode from 'react-barcode';
import logo from "assets/logo.png";
import SenderReciver from './SenderReciver';
import PublicIcon from "@mui/icons-material/Public";
import Form1RightSection from './Form1RightSection';
import moment from 'moment';
import SenderRecieverForm2 from './SenderRecieverForm2';
import { currencyTypes } from '../../Parcel';
import { setVAT } from '../Type_B/PrintType_B';

const useStyeles = makeStyles((theme) => ({
    wrapper: {
      padding: "2px 20px 0 20px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    form1: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    form1Header: {
      display: "flex",
      justifyContent: "space-between",
      height: 70,
      width: "100%",
      marginBottom: 5,
    },
    form2Header: {
      display: "flex",
      justifyContent: "space-between",
      height: 50,
      width: "100%",
    },
    form1LeftSection: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      minHeight: "100%",
    },
    form1CenterSection: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      paddingTop: 10,
      fontWeight: 900,
      fontSize: 32,
      opacity: 0.7,
    },
    packageText: {
      fontSize: 12,
      fontWeight: 400,
      textAlign: "left",
    },
    headBarCode: {
      height: 51,
      textAlign: "left",
      boxSizing: "border-box",
    },
    officeText: { textAlign: "left", fontSize: 12, fontWeight: 400 },
    form1RightSection: {
      height: "100%",
      justifyContent: "space-between",
      alignItems: "self-end",
      alignContent: "flex-end",
      display: "flex",
      // position: "relative",
      textAlign: "right",
      flexDirection: "column",
    },
    imgLogo: {
      width: 75,
      // position: "absolute",
      // bottom: 0,
      // right: 0,
    },
    form2: { color: "#888" },
    section3: { display: "flex", height: "max-content" },
    vat: {
      width: "90%",
      minWidth: '280px',
      border: "1px solid black",
      display: 'flex',
      fontSize: 10,
      flexDirection: 'column',
      justifyContent: 'space-between',
      textAlign: 'center',
      "& div": {
        height: "12px",
      }
    },
    row: {
      display: 'flex',
      borderTop: '1px solid black',
      "&:first-child": {
          border: 'none'
      }
    },
    col76:{
      width: "77%",
      textAlign: 'end',
      paddingRight: '2px',
      borderRight: '1px solid black',
    },
    col42:{
      width: "42%",
      textAlign: 'start',
      borderRight: '1px solid black',
      paddingLeft: '3px'
    },
    col25:{
      width: "25%",
      borderRight: '1px solid black',
      "&:last-child": {
          border: 'none'
      }
    },
    col9:{
      width: "9%",
      borderRight: '1px solid black'
    },
    header: {
      textAlign: 'center',
      fontWeight: 'bold'
    }
  }));

const PrintType_A = ({data, tracking_number}) => {
    const classes = useStyeles();
    const { state: profile } = useContext(AuthContext);
    const name =
      profile.firstname[0]?.toUpperCase() + profile.lastname[0]?.toUpperCase();

  return (
    <div>
      <div className={classes.wrapper}>
        <div className={classes.form1}>
          <div className={classes.form1Header}>
            <div className={classes.form1LeftSection}>
              <div className={classes.headBarCode}>
                {!!tracking_number && (
                  <Barcode
                    value={tracking_number}
                    displayValue={false}
                    height={35}
                  />
                )}
              </div>
              <div className={classes.officeText}>
                Office of origin/Գրասենյակ։ {name}
              </div>
              <div className={classes.packageText}>Package and parcel form</div>
            </div>
            <div className={classes.form1CenterSection}>
              {/* {data?.sender?.country_code === "AM"
                ? `[ ${data?.receiver?.country_code} / ${
                    data?.receiver?.country_code === "AM"
                      ? data?.receiver?.states_name || ''
                      : data?.receiver?.other_states_name || ''
                  } ]`
                : data?.parcel_local_delivery === "1" &&
                  `[ ${
                    data?.receiver?.country_code === "AM"
                      ? data?.receiver?.states_name || ''
                      : data?.receiver?.other_states_name || ''
                  } ]`} */}
                  {data?.parcel_local_delivery === "1" ?
                  `[ ${
                    data?.receiver?.country_code === "AM"
                      ? data?.receiver?.states_name || ''
                      : data?.receiver?.other_states_name || ''
                  } ]` : 
                  `[ ${data?.receiver?.country_code} / ${
                    data?.receiver?.country_code === "AM"
                      ? data?.receiver?.states_name || ''
                      : data?.receiver?.other_states_name || data?.receiver?.states_name || ''
                  } ]`}
              {data?.sender?.taxpayer_id === "1" && " / Legal"}
            </div>
            <div className={classes.form1RightSection}>
              <img src={logo} alt="" className={classes.imgLogo} />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "38%" }}>
              <SenderReciver data={data} />
            </div>
            <div style={{ width: "59%" }}>
              <Form1RightSection
                data={data}
                tracking_number={tracking_number}
              />
            </div>
          </div>
          <div className={classes.section3}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                fontSize: 9,
                paddingBottom: 2,
                marginTop: 1,
                borderBottom: "2px solid #444",
                color: "#777",
                textAlign: "start",
                width: "100%",
                height: "max-content",
                alignItems: "start",
              }}
            >
              <div style={{ fontSize: 8, lineHeight: "7px" }}>
                By signing this paper, I confirm that in the parcel, there is no
                packaged or unpackaged tobacco, narcotics, weapons, explosive or
                combustible materials, items of ethnic value and all the other
                not allowed items mentioned in the terms and conditions of
                <span style={{ fontWeight: 600 }}>
                  {" "}
                  {data.sender.country_id === "20" ? "BAREKAM BELGIUM BV" : "BAREKAM POLAND Sp. Z o.o."}
                </span>{" "}
                (<i>www.barekam.am/Terms-EN.pdf</i>) and I'm acquainted with the
                terms and conditions of{" "}
                <span style={{ fontWeight: 600 }}>
                {data.sender.country_id === "20" ? "BAREKAM BELGIUM BV" : "BAREKAM POLAND Sp. Z o.o."}
                </span>{" "}
                (in written and/or verbal forms).
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  fontSize: 11,
                  color: "#777",
                }}
              >
                <div>
                  Date: {moment(data?.date_added).format("YYYY - MM - DD")}
                </div>
                <div>Signature: </div>
                <div
                  style={{
                    fontSize: 25,
                    fontWeight: 600,
                    marginRight: 10,
                  }}
                >
                  {tracking_number}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.wrapper}>
        <div className={classes.form2}>
          <div className={classes.form2Header}>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                justifyContent: "end",
                paddingBottom: 2,
              }}
            >
              <div className={classes.packageText}>
                Tracking No / Բեռնային համար:
                <span
                  style={{
                    fontSize: 12,
                    color: "#777",
                    fontWeight: "bolder",
                  }}
                >
                  {tracking_number}
                </span>
              </div>
              <div className={classes.packageText}>
                Office of origin/Գրասենյակ: {name}
              </div>
              <div className={classes.packageText}>
                Package and parcel form / Confirmation of sending a copy
              </div>
            </div>

            <div className={classes.form1RightSection}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <PublicIcon style={{ width: 18 }}/>
                www.barekam.am
              </div>
              <img src={logo} alt="" className={classes.imgLogo} />
            </div>
          </div>
          <SenderRecieverForm2 data={data} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              fontSize: 9,
              marginTop: 4,
              borderBottom: "2px solid #888",
              paddingBottom: 5,
              textAlign: "start",
              width: "100%",
              height: "max-content",
              alignItems: "start",
            }}
          >
            <div style={{ fontSize: 8, lineHeight: "7px" }}>
              By signing this paper, I confirm that in the parcel, there is no
              packaged or unpackaged tobacco, narcotics, weapons, explosive or
              combustible materials, items of ethnic value and all the other not
              allowed items mentioned in the terms and conditions of
              <span style={{ fontWeight: 600 }}>
                {" "}
                {data.sender.country_id === "20" ? "BAREKAM BELGIUM BV" : "BAREKAM POLAND Sp. Z o.o."}
                {/* BAREKAM POLAND Sp. z o.o. */}
              </span>{" "}
              (<i>www.barekam.am/Terms-EN.pdf</i>) and I'm acquainted with the
              terms and conditions of{" "}
              <span style={{ fontWeight: 600 }}>
              {data.sender.country_id === "20" ? "BAREKAM BELGIUM BV" : "BAREKAM POLAND Sp. Z o.o."}
                {/* BAREKAM POLAND Sp. z o.o. */}
                </span>{" "}
              (in written and/or verbal forms).
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: 5,
                alignItems: "end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  minHeight: 55,
                  justifyContent: "space-between",
                }}
              >
               {setVAT(data, classes)}   
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    whiteSpace: "pre",
                  }}
                >
                  BAREKAM SENDER ID: {data?.sender?.country_code}
                  {data?.sender.contract_id} {"\n"}
                  BAREKAM RECEIVER ID: {data?.receiver?.country_code}
                  {data?.receiver.contract_id}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    Date: {moment(data?.date_added).format("YYYY-MM-DD")}
                  </div>
                </div>
              </div>

              <div
                style={{
                  fontSize: 10,
                  fontWeight: 400,
                  marginRight: 5,
                  minHeight: 55,
                }}
              >
                {data?.payment_bank_transfer ?
                  data?.payment_bank_transfer > 0 && (
                    <>
                      <div style={{ fontWeight: 600 }}>
                      <span >{data.sender.country_id === "179" ? "Nazwa firmy" : "Company name"}: </span>{" "}
                        {data.sender.country_id === "20" ? "BAREKAM BELGIUM BV" : "BAREKAM POLAND Sp. z o.o."}
                      </div>
                      {data.sender.country_id === "179" ? 
                        <div style={{ fontWeight: 600 }}>
                            <span >Adres: </span>{" "}
                            ul. Rodziny Hiszpańskich 8, D1.04, 02-685 Warszawa, Poland
                        </div> : null}
                      {data.sender.country_id === "20" ? 
                        <div style={{ fontWeight: 600 }}>
                            <span >Address: </span>{" "}
                            Predikherenlaan 24, 2500 Lier, Belgium
                        </div> : null}
                      <div style={{ fontWeight: 600 }}>
                        <span style={{ fontWeight: 600 }}>
                          Bank account number:{" "}
                        </span>
                        {data.sender.country_id === '20'? 
                            data.sender.country_id === "179" ||
                            data.receiver.country_id === "179"
                              ? "Acc. Zloty: PL66 1090 0088 0000 0001 5287 8299"
                              : "Acc. EURO: PL31 1090 0088 0000 0001 5287 8347"
                        : "IBAN: BE37 0689 5086 2028"}
                      </div>
                      <div style={{ fontWeight: 600 }}>
                        <span style={{ fontWeight: 600 }}>
                        {data.sender.country_id === "20" ? "VAT number:" : "NIP:"}{" "}
                        </span>
                        {data.sender.country_id === "20" ? "BE1003696909" : "1133076356"}
                      </div>
                      <div style={{ fontWeight: 600 }}>
                        <span style={{ fontWeight: 600 }}>Number BIC: </span>{" "}
                        WBKPPLPP
                      </div>
                      <div style={{ fontWeight: 600 }}>
                        <span style={{ fontWeight: 600 }}>Invoice Number: </span>{" "}
                        {tracking_number}
                      </div>
                      <div style={{ fontWeight: 600 }}>
                        <span style={{ fontWeight: 600 }}>
                          Transfer amount:{" "}
                        </span>
                        {data?.payment_bank_transfer} {currencyTypes[data.currency_type_id + "symbol"]}
                      </div>
                      <div style={{ fontWeight: 600 }}>
                        <span style={{ fontWeight: 600 }}>Title: </span> Package
                        -{tracking_number}
                      </div>
                      {/* {setVAT(data, classes)} */}
                    </>
                  ) : (<>
                    <div style={{ fontWeight: 600 }}>
                        <span >{data.sender.country_id === "179" ? "Nazwa firmy" : "Company name"}: </span>{" "}
                        {data.sender.country_id === "20" ? "BAREKAM BELGIUM BV" : "BAREKAM POLAND Sp. z o.o."}
                    </div>
                    {data.sender.country_id === "179" ? 
                      <div style={{ fontWeight: 600 }}>
                          <span >Adres: </span>{" "}
                          ul. Rodziny Hiszpańskich 8, D1.04, 02-685 Warszawa, Poland
                      </div> : null}
                    {data.sender.country_id === "20" ? 
                      <div style={{ fontWeight: 600 }}>
                          <span >Address: </span>{" "}
                          Predikherenlaan 24, 2500 Lier, Belgium
                      </div> : null}
                    <div style={{ fontWeight: 600 }}>
                        <span style={{ fontWeight: 600 }}>
                        {data.sender.country_id === "20" ? "VAT number:" : "NIP:"}{" "}
                        </span>
                        {data.sender.country_id === "20" ? "BE1003696909" : "1133076356"}
                    </div>
                    <div style={{ fontWeight: 600 }}>
                      <span style={{ fontWeight: 600 }}>Invoice Number: </span>{" "}
                      {tracking_number}
                    </div>
                    {(data?.payment_cash || data?.price) && 
                    <div style={{ fontWeight: 600 }}>
                      <span style={{ fontWeight: 600 }}>Cash paid: </span>{" "}
                     {data?.payment_cash || data?.price}
                    </div>}
                    {/* {setVAT(data, classes)} */}
                  </>)}
                  <div style={{ display: "flex", alignItems: "center", justifyContent: 'right' }}>Address/Հասցե Yerevan, 0082, Isakov 6</div>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: 'right' }}>Tel./ Հեռ. 060 27 07 27</div>
              </div>
            </div>
          </div>
          {/* <Form2PackageInfo data={data} /> */}
        </div>
      </div>
    </div>
  )
}

export default PrintType_A